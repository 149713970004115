@import "../../../styles/mixins";

.contentFooter {
    padding: 320px 0 0 0;
    font-size: 1.5rem;
    position: relative;
    background: url('/images/footer_bg.svg') top center repeat-x;
    @media (max-width: 768px) {
       padding-top: 270px;
    }
    p {
        color: $white50;
        text-align: center;
        margin-top: 56px;

    }
}
.wrapper {
    background: $black;
    padding: 0 2rem 4rem;
}
.footerNav {
    @include flex-between();
    max-width: 1024px;
    margin: 0 auto;
    a.link {
        font-size: 2rem;
        line-height: 1.5em;
        margin: 1rem;
        color: $white;
        &:hover {
            color: $orange;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
  > * {
    margin: 2rem 1.5rem;
  }
}

.mobile {
  @media (min-width: 769px) {
      display: none;
  }
}

.desktop {
  @media (max-width: 768px) {
      display: none;
  }
}
