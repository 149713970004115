@import '../variables.scss';

.checkbox {
  input[type=checkbox] {
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }
  div {
    border: 1px solid $colorNeutral;
    background: $colorWhite;
    height: 14px;
    width: 14px;
    transition: box-shadow $defaultTransitionDuration ease;
  }

  input[type=checkbox]:checked + div:after {
    display: block;
    content: '';
    margin-top: -2px;
    background-size: cover;
    background-image: url('./check.svg');
    height: 16px;
    width: 16px;
  }
  input[type=checkbox]:indeterminate + div:after {
    display: block;
    content: '';
    margin-top: -2px;
    margin-left: -2px;
    background-size: cover;
    background-image: url('./indeterminate.svg');
    height: 16px;
    width: 16px;
  }
  &:focus-within, &:focus {
    input[type=checkbox] + div {
      @include focusOutline();
    }
  }
}
