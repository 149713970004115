@import '../../styles/colors';



.sidepanel {
    width: 100%;
    max-width: 100vw;
    text-align: left;
    margin: 0 auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background: $black75;
    z-index: 9999;
    transition: all 0.2s ease;
    pointer-events: none;
    opacity: 0;
}
.sidepanelWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 628px;
    box-shadow: 4px 0 8px  $black15;
    transition: transform 0.3s ease, opacity 0.6s ease;
    transform: translateX(100%);
    @media (max-width: 768px) {
        width: auto;
        left: 0;
    }
}
.open {
    pointer-events: all;
    opacity: 1;
    
    .sidepanelWrapper {
        opacity: 1;
        transform: translateX(0%);
    }
}

.closeBtn {
    position: fixed;
    top: 4rem;
    right: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: $black;
    color: $white;
    font-size: 3rem;
    transition: all 0.3s ease;

    &:hover {
        transform: rotate(90deg);
    }

    @media (max-width: 768px) {
        top: 2rem;
        right: 2rem;
    }    
    
}
.clicker {
    position: fixed;
    left: 0;
    top: 0;
    bottom:0;
    right: 628px;
}
.sidepanelFooter {
    background-color: $orange;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;
    z-index: 4000;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    color: #fff;
    font-weight: bold;
    font-size: 2.5rem;
    letter-spacing: 0.05em;
    &.muted {
        background-color: $lightGray;
        color: $black;
    }
    @media (max-width: 768px) {
        padding: 0 2rem;
    }

}
.sidepanelContent {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow-y: auto;
    padding: 5rem;
    @media (max-width: 768px) {
        padding: 2rem;
    }

}
