@import '../../styles/colors';
@import '../../styles/mixins';

.navMenu {
    display:flex;
    font-size: inherit;
    font-weight: bold;
    color: $black20;
    overflow: auto;
    font-size: 2.5rem;
    line-height: 1.5em;;
    scroll-behavior: smooth;
    a {

        color: $black20;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-right: 1em;
        &:global(.xmas) {
          color: $crimson;
        }
        &:global(.easter) {
          color: $lightGreen;
        }
        &:global(.help) {
          background: linear-gradient(-180deg, #0057b7 57%, #ffd700 43%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          opacity: 1;
        }
    }
    @media screen and (max-width: 960px) {
        margin: 0 -2rem;
        a {
            margin-right: 2rem;
            &:first-child {
                margin-left: 2rem;
            }
        }
    }
}
:global(.highlight), a[href]:global(.highlight), a:global(.highlight) {
    color: $black;
    &:global(.xmas) {
      color: $crimson;
    }
    &:global(.easter) {
      color: $lightGreen;
    }
    &:global(.help) {
      background: linear-gradient(-180deg, #0057b7 57%, #ffd700 43%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 1;
    }
}
