@import "../../styles/colors";
@import "../../styles/mixins";

.buttonGroup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  border: 0.5rem solid $orange;
  background: $orange;
  color: $white;
  font-size: 3rem;
  padding: 2.5rem 5rem;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin: 20px;
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  &.colorReverse {
    background: $white;
    color: $orange;
  }
}

