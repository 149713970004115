@import '../../styles/colors';
@import '../../styles/mixins';


.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;

}

.userLink {
  cursor: pointer;
  margin-right: 36px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black;
  opacity: 0.7;
  img {
    margin-right: 8px;
  }
  &:hover {
    text-decoration: none;
    opacity: 1;
  }
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    font-size: 20px;
    padding: 12px 0 12px 12px;
    margin-right: 0;
    &.gentle {
      span{ display: none;}
      padding: 1rem
    }
  }
}
