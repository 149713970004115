@import '../variables.scss';

.container {
  @include showFocused();
  display: inline-flex;
  &.fill {
    width: 100%;
  }
}

.select {
  display: inline-block;
  min-width: 170px;

  &.fill {
    flex-grow: 1;
  }

  :global(.s__control) {
    height: $heightNormal;
    border: 1px $colorNeutral solid;
    border-right: 0;
    box-sizing: border-box;
    border-radius: $radius 0 0 $radius;
    font-family: $fontFamily;
    font-size: 12px;
    color: $colorText;
    font-weight: 500;
    &:hover {
      border: 1px $colorNeutral solid;
      border-right: 0;
    }
    &:global(.s__control--is-disabled){
      background-color: $colorDisabled;
    }

    &:global(.s__control--is-focused) {
      box-shadow: none;
    }
    :global(.s__placeholder) {
      opacity: 0.5;
      font-weight: normal;
    }
    :global(.s__indicator-separator){
      display:none;
    }
    :global(.s__dropdown-indicator){
      width: 28px;
      padding: 0 8px 0 2px;
    }
    :global(.s__clear-indicator) {
      width: 20px;
      padding: 0;
      padding-left: 5px;
    }
  }


  &.multiline {
    :global(.s__control) {
      height: auto;
    }
  }

  :global(.s__option){
    font-size: 12px;
    color: $colorText;
    text-align: left;
    &:global(.s__option--is-focused){
      background-color: lighten($colorPrimary, 42);
    }
    &:global(.s__option--is-selected){
      background-color: $colorPrimary;
      color: $colorGray16;
      font-weight: 500;
    }
  }

  :global(.s__value-container--is-multi){
    flex-wrap: nowrap;
  }

  &.multiline {
    :global(.s__value-container--is-multi){
      flex-wrap: wrap;
    }
  }

  :global(.s__multi-value){
    background-color: $colorWhite;
    border: 1px solid $colorNeutral;
    height: 24px;
    line-height: 10px;
    :global(.s__multi-value__label){
      padding: 6px;
    }
    :global(.s__multi-value__remove){
      background-color: inherit;
      color: $colorDanger;
      transition: all $defaultTransitionDuration ease;
      &:hover{
        svg{
          filter: drop-shadow( 0 0 1px rgba($colorDanger, 0.5));
        }
        color: darken($colorDanger, 10);
      }
    }
  }

  :global(.s__menu) {
    border-radius: $radius;

    :global(.s__menu-notice--no-options){
      font-size: 10px;
    }
  }
}
