@import '../variables.scss';

.pageBtn {
  box-sizing: border-box;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  border-radius: $radius;
  height: 44px;
  width: 44px;
  background: $colorBackground;
  color: $colorPager;
  border: $colorPager 1px solid;
  &.active {
    box-shadow: 0 0 9px $colorPager;
    background: $colorPager;
    color: $colorBackground;
  }
}
.pageDots {
  display: inline-block;
  text-align: center;
  width: 36px;
}

.pageBtn + .pageBtn {
  margin-left: 5px;
}
