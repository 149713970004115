@import "../../../styles/mixins";

.banner {
  margin: 4rem 0;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  background: url("./umbrella2.svg") left center no-repeat #f1f1f1;
  background-size: auto 80%;
  @media (max-width: 768px) {
    flex-direction: column;
    background-size: 70% auto;
    background-position: left 5%;
    justify-content: center;
    margin: 1rem 0;
  }
}
.btnWrapper {
  padding: 0 4rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 0 4rem 0;
    justify-content: center;
  }

}
.bannerConent {
  display: flex;
  flex: 1 1 60%;
  flex-direction: column;
  padding:4rem 0 4rem 20%;
  text-align: left;
  
  h1 {
    font-size: 3rem;
    margin: 0;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    color: $black75;
    max-width: 450px;
    
  }
  @media (max-width: 768px) {
    padding: 70% 22px 32px 22px;
    flex: 1 1 auto;
    text-align: center;
    h1 {
      font-size: 22px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
