
$colorBlack:  #000;
$colorGray4:  #4D4D4D;
$colorGray5:  #555;
$colorGray10: #A0A0A0;
$colorGray13: #DEDEDE;
$colorGray16: #F7F7F7;
$colorWhite:  #FFF;


$colorPrimary: #34A7DB;
$colorMain:    #141E30;
$colorDanger:  #D40000;
$colorSuccess: #00A430;
$colorWarn:    #FF7733;

$colorPager:   #202939;

$colorText: $colorGray4;

$colorOverridden: #36A;

$colorNeutral: $colorGray13;
$colorBackground: $colorGray16;
$colorDisabled: $colorGray16;


$heightSmall: 28px;
$heightNormal: 40px;
$heightBig: 60px;

$radius: 3px;

$fontFamily:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

$defaultTransitionDuration: 0.2s;

@mixin focusOutline {
  outline: none;
  box-shadow: 0 0 2px 1pt $colorPrimary;
}

@mixin showFocused {
  transition: box-shadow $defaultTransitionDuration ease;
  &:focus-within, &:focus {
    @include focusOutline();
  }
}
