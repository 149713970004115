@import '../../../styles/colors';
@import '../../../styles/mixins';

.checkboxWrapper {
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 3rem 3rem 0;
    color: $black30;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        color: $black50;
    }
    &.checked {
        color: $black;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.label {
    cursor: pointer;
    .disabled & {
        cursor: not-allowed;
    }
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: normal;
    word-wrap:normal;
    a {
        margin: 0 4px;
    }
}

.checkIcon {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    background-color: $lightGray;
    border-radius: 6px;
    margin-right: 1rem;
    > div {
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background: $white;
    }
    &.radio {
        border-radius: 100%;
    }
    .checked & {
        background: $black;
    }
    .checked &.error {
      background-color: $crimson10;
    }
}
