@import "../../../styles/colors";

.sectionTitle {
  text-align: left;
  color: $black50;
  opacity: 0.4;
  @media (max-width: 768px) {
    text-align: center;
  }
}

.categoriesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.category {
  display: flex;
  flex-direction: column;
  width: 180px;
  text-align: center;
  margin: 5rem 3rem;
  > div {
    margin: 0 auto;
    background-color: $black;
    width: 168px;
    height: 140px;
    border-radius: 3rem;
    position: relative;
  }
  img {
    left: -5rem;
    top: -8rem;
    position: absolute;
    width: 200px;
  }
  a {
    color: $black75;
    font-weight: 500;
    font-size: 3rem;
    margin-top: 2rem;
  }
}

.showMore {
  margin-top: 3rem;
  text-align: center;
}
