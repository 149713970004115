@import '../variables.scss';

.label{
  display: flex;
  &.top {
    flex-direction: column;
  }
  &.bottom {
    flex-direction: column-reverse;
  }
  &.left {
    flex-direction: row;
    align-items: center;
  }
  &.right {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  &.fill {
    width: 100%;
  }
}

.labelText  {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: $colorText;
  text-align: left;
}
.left > .labelText {
  margin-right: 10px;
}
.top > .labelText {
  margin-left: 8px;
  margin-bottom: 8px;
}
.bottom > .labelText {
  margin-left: 8px;
  margin-top: 8px;
}
.right > .labelText {
  margin-left: 10px;
}

.inverted > .labelText {
  color: $colorWhite;
}

.gentle > .labelText {
  text-transform: none;
  font-weight: normal;
}

.entry.center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
