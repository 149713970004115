@import "../../../styles/mixins";
@import '../../../styles/vars';

.contentWrapper {
  p {
    font-size: 2rem;
  }

  a:visited {
    color: $orange;
  }

  :global(.map) {
    margin-top: 2rem;

    svg {
      max-width: 100%;
    }
  }

  :global(.rules) {
    font-size: 12px;
    font-weight: normal;

    p {
      font-size: inherit;
      font-weight: inherit;
      line-height: 1.3em;
    }

    table {
      border-collapse: collapse;
    }

    table,
    td,
    th {
      border: solid 1px $black50;
    }

    th,
    td {
      padding: 4px;
    }

    th {
      background-color: $lightGray;
    }
  }

  button {
    font-size: 18px;
  }

  :global(.black50) {
    color: $black50;
  }

  :global(.fadedText) {
    max-width: 960px;
  }

  :global(.max960) {
    max-width: 960px;
  }

  :global(p.tel) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    align-items: center;
    margin-bottom: 36px;

    span {
      color: $black50;
      font-size: inherit;
    }

    a[href],
    a[href]:visited {
      color: $black !important;
      text-decoration: underline;
      display: inline-flex;
      margin-right: 24px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  :global(.split) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    >section {
      flex: 0 1 45%;
      margin-bottom: 3em;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      justify-content: center;

      >section {
        width: 100%;
      }
    }
  }

  :global(.deliveryHeader) {
    color: $black50;
    font-weight: 400;
  }

  :global(.deliveryToOfficeHeader) {
    font-size: 18px;
    line-height: 30px;
    font-weight: lighter;
  }

  :global(.deliveryToOfficeForm) {
    :global(.textFieldsForm) {
      width: 500px;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    svg {
      width: 550px;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    :global(.checkbox) {
      color: black;
      text-transform: none;

      b {
        font-weight: 500;
      }

      p {
        font-size: 12px;
        font-weight: lighter;
        margin: 0
      }
    }
  }

  @media (max-width: 768px) {
    :global(.h1) {
      font-size: 32px;
      line-height: 38px;
    }

    :global(.h2) {
      font-size: 28px;
      line-height: 36px;
    }

    :global(.h3) {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.columns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  >div {
    flex: 0 1 45%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

:global(.rangeBox) {
  flex: 40vw;

  @media (max-width: 960px) {
    flex: 100%;

    form {
      width: calc(100vw - 160px);
    }
  }
}