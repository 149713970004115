@import "../../../styles/mixins";

.codeCheckResult {
  @include flex-between();
  padding: 60px 42px;
  margin: 64px 0;
  background: $orange;
  border-radius: 12px;
  color: $white;
  &.failed {
    background: $black;
  }
  a {
    color: $black;
  }
  @media (max-width: 960px) {
    margin: 44px 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
  }
  .text {
    padding-right: 32px;    
  }
  big {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: block;
    margin-bottom: 10px;
  }
  span {
    font-size: 16px;
    line-height: 19px;
    opacity: 0.7;
  }
  .button {
    flex: 0 1 30%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 32px;
    }
  
  }  

}



