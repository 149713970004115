@import '../variables.scss';

@mixin color($background, $font, $outlineBackground, $outlineFont, $outlineBorder) {
  background-color: $background;
  color: $font;
  &:hover {
    background-color: darken($background, 10);
  }
  &:disabled {
    background-color: transparentize($background, 0.8);
    color: transparentize($font, 0.2);
  }
  &.outline {
    background-color: $outlineBackground;
    border: 1px solid $outlineBorder;
    color: $outlineFont;
    &:hover {
      border: 1px solid darken($outlineBorder, 10);
      color: darken($outlineFont, 10);
    }
    &:disabled {
      border: 1px solid transparentize($outlineBorder, 0.8);
      color: transparentize($outlineFont, 0.8);
    }
  }
}

.button {
  @include color($colorNeutral, $colorGray5, $colorWhite, $colorGray5, $colorGray5);
  @include showFocused();
  position: relative;
  border: none;
  border-radius: $radius;
  font-family: $fontFamily;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  transition: all $defaultTransitionDuration ease;
  cursor: pointer;
  box-sizing: border-box;

  &.primary {
    @include color($colorPrimary, $colorWhite, $colorWhite, $colorPrimary, $colorPrimary);
  }
  &.lightgray {
    @include color($colorGray16, $colorGray10, $colorWhite, $colorGray10, $colorGray10);
  }
  &.transparent {
    @include color(transparent, $colorGray10, transparent, $colorWhite, transparent);
  }
  &.danger {
    @include color($colorDanger, $colorWhite, $colorWhite, $colorDanger, $colorDanger);
  }
  &.warn {
    @include color($colorWarn, $colorWhite, $colorWhite, $colorWarn, $colorWarn);
  }
  &.success {
    @include color($colorSuccess, $colorWhite, $colorWhite, $colorSuccess, $colorSuccess);
  }

  &.small {
    height: $heightSmall;
    font-size: 11px;
    padding: 0 10px;
  }

  &.medium {
    height: $heightNormal;
    font-size: 12px;
    padding: 0 15px;
  }

  &.big {
    height: $heightBig;
    font-size: 15px;
    padding: 0 20px;
  }
}
