@import '../../styles/colors';
@import '../../styles/mixins';

.fieldset {
    border: solid 1px $lightGray;
    border-radius: 1.5rem;
    padding: 2.5rem 3rem;
    margin-top: 3rem;
}
.fieldsetFilled {
    background-color: $lightGray;
}
h3.fieldsetHeader {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: -4rem;   
    margin-bottom: 0.5rem;
    text-align: left;

}
