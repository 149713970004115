@import '../variables.scss';

.dropzone {
  padding: 30px;
  text-align: center;
  outline: none;
  color: $colorText;
  cursor: pointer;
  margin-bottom: 5px;

  border: 1px dashed $colorNeutral;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  > div {
    display: flex;
    min-width: 0;
    overflow: hidden;
    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}
