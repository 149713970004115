@import '../../../styles/colors';
@import '../../../styles/vars';
@import '../../../styles/mixins';


div.voucherDetails {
    width: 100%;
    @include flex-between();
    font-size: 2rem;
    margin-top: -2rem;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }

    p {
        font-size: inherit;
        color: $black50;
        flex: 1 0 60%;
        margin: 1rem 0;
        @media (max-width: 768px) {
            flex: 0 1 60%;
        }

    }
    b {
        font-size: inherit;
        display: flex;
        justify-content: flex-end;
        padding: 0 4rem 0 0;
        @media (max-width: 768px) {
            padding: 0 1rem 0 0;
        }

    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        border-radius: 1rem;
        background-color: $lightGray;
        color: $black50;
        font-weight: 500;
        flex: 0 0 auto;
    }
}
.voucherValue {
    white-space: nowrap;
}
