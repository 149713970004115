@import "../../../styles/mixins";

.balanceItem {
  @include flex-between();
  font-size: 2rem;
  color: $black50;
  font-weight: 500;
  margin-top: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    div {
        text-align: left !important;
        margin-bottom: 1rem;
    }
  }
  > div {
    flex: 1 1 30%;
    text-align: left;
    &:first-child {
      color: $black;
    }
    &:last-child {
      text-align: right;
    }

  }
  section {
    flex: 1 1 40%;
    @include flex-between();
    div {
        flex: 1 1 50%;
        text-align: center;
    }
}

}

.itemDate {
  color: $black50;
}

a.link {
  color: $black;
  text-decoration: underline;
}
