.actionsRow {
  position: sticky;
  left: 0;
  top: 0;
  background: white;
  box-shadow: 0 4px 4px #eee;
  z-index: 5;
  padding-bottom: 5px;
}

.center {
  margin: auto;
  position: absolute;
  z-index: 100;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  white-space: nowrap;
}
