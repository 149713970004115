@import "./_colors.scss";

@mixin flex-between() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin order-irem-box() {
  border-radius: 2rem;
  border: solid 1px $black15;
  padding: 1px 3rem 3rem 3rem;
  margin: 3rem 0;
}

@mixin order-item-header() {
  font-size: 3rem;
  margin-top: -0.5em;
  margin-bottom: 2rem;
  line-height: 1em;
  text-shadow: 1px -1px 0 #fff;
  font-weight: 500;
}

@mixin btn-basic() {
  line-height: 1em;
  border-radius: 1em;
  padding: 0.5em 1em;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

@mixin button {
  font-size: 18px;
  border-radius: 2rem;
  padding: 2rem 3rem;
  font-weight: 500;
}

@mixin section {
  border-radius: 1.5rem;
  padding: 7rem 5rem;
  margin-bottom: 5rem;
  border: solid 1px $black10;

  @media (max-width: 768px) {
    padding: 2rem;
  }
}

@mixin section-header {
  background: $black;
  color: $white;
  @include section();
}

@mixin not-printable {
  @media print {
    display: none;
  }
}

@mixin wrapper($paddingTop: 0) {
  max-width: 1300px;
  padding: $paddingTop 10rem;
  @media (max-width: 768px) {
    padding: 2rem;
  }  
}

@mixin mob-con-p {
  font-size: 2rem;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  font-weight: normal;
}