@import "../../../styles/mixins";

.banner {
  margin: 6rem 0 -10rem 0;
  @media (max-width: 768px) {
    justify-content: center;
    margin: 3rem -2rem -3rem -2rem;
  }
  img {
    max-width: 100%;
    width: 100%;
    border-radius: 3rem;
    @media (max-width: 768px){
      padding: 0 1rem;
    }
  }
  a {
    display: block;
  }
}
