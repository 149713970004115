@import "./styles/colors";

::selection {
  color: $white;
  background: $orange;
}

button {
  border: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  cursor: pointer;
  &:global(.link) {
    color: $orange;
    &:visited {
      color: $orange50;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.App {
  :global(.Toastify__toast-container) {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }
  :global(.Toastify__toast) {
    font-size: 2.5rem;
    margin-bottom: 0;
  }
  :global(.Toastify__toast--success) {
    background-color: $orange;
  }
  :global(.Toastify__toast--error) {
    background-color: $crimson
  }
  :global(.Toastify__toast-body) {
    max-width: 1280px;
    margin: auto;
  }
  :global(.Toastify__close-button) {
    font-size: 2.5rem;
    margin: auto 2rem;
  }
}
