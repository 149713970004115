@import "../../styles/mixins";

.filterWrapper {
  position: relative;
}
.filterBtn {
  @include btn-basic();
  font-size: 2rem;
  background: $lightGray;
  color: $black;
  outline: none;
  span {
    margin-left: 1rem;
  }
  svg {margin-bottom: 2px;}
  svg * {
    fill: $black;
  }
  &.active {
    background-color: $black;
    svg * {
      fill: $white;
    }
    color: $white;
  }
  &:hover {
    background-color: $orange;
    color: $white;
    svg * {
      fill: $white;

    }
  }
  @media (max-width: 768px) {
    span {display: none;}
  }
}
.filterOptions {
  position: absolute;
  right: 0;
  top: 4rem;
  padding: 1rem;
  background: $white;
  border-radius: 2rem;
  box-shadow: -4px 14px 30px $black15;
   > div {
     margin: 1rem 0 1rem 1rem;
   }
}
.filterButtons {
  padding: 1rem 1rem 1rem 0;
  @include flex-between();
  a {
    cursor: pointer;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}
.clearFilter {
  color: $black50;
}
.saveFilter {
  color: $orange;

}


.clicker {
  position: fixed;
  left: 0;
  top: 0;
  bottom:0;
  right: 0;
}
