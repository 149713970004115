@import '../../../styles/colors';
@import '../../../styles/mixins';

.orderHeader {
 @include  order-item-header();
 @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
 }
}

.orderItem {
    background:$lightGray;
    border-radius: 2rem;
    padding: 1px 3rem 3rem 3rem;
    margin: 3em 0;
    footer {
        @include flex-between();
    }
    p {
        font-size: 14px;
        line-height: 17px;
        color: $black50;
    }
}
.orderItemActions {
    font-size: 2rem;
    display: flex;
    align-items: center;
    > * {
        margin-right: 2rem;
    }
}
.orderValue {
    font-size: 18px;
}

.orderItemPackage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    b {
        font-size: 14px;
    }
}