@import "../variables.scss";

.tooltip {
  display: inline-block;
}

.tooltipContent {
  :global(.react-tooltip-lite) {
    background: rgba($colorMain, 0.98);
    box-shadow: 0 0 4px $colorBlack;
    color: $colorBackground;
    border-radius: $radius;
    font-size: 12px;
  }
  :global(.react-tooltip-lite-arrow) {
    border-color: rgba($colorMain, 0.98);
  }
}

.content {
  padding: 10px;
}
