@import "../variables.scss";

.textValue {
  padding: 10px 20px;
  background-color: #eee;
  border-radius: $radius;
  font-size: 12px;
}

.label {
  margin-top: 10px;
}
.chevron {
  font-size: 15px;
  font-weight: bold;
}

.array .array {
  background: #fafafa;
}
.array {
  display: flex;
  flex-direction: column;
  .newItemButton {
    border-radius: $radius;
    outline: none;
    border: none;
    padding: 7px 15px;
    background: $colorPrimary;
    color: $colorWhite;
    margin: auto;
    margin-right: 10px;
  }
  .list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  .listItem {
    list-style: none;
    // margin: 15px 0;
    border: 1px solid #eee;
    border-top: none;
    &:first-child {
      border-top: 1px solid #eee;
      margin-top: 0;
    }
    .removeButton {
      background-color: white;
      color: $colorDanger;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }
}

.itemHeader {
  text-align: left;
  padding: 25px 20px;
  &.clickable {
    cursor: pointer;
  }
}
.itemBody {
  padding: 0 10px 25px 25px;

  display: flex;
  flex-direction: row;
  > :first-child {
    flex: 1;
  }
}

.check {
  margin-left: 8px;
  margin-top: 10px;
}

.hidePanel {
  position: sticky;
  top: -5px;
  background: white;
  border-bottom: 1px solid #eee;
  z-index: 6;
}

.codeModal {
  overflow: hidden !important;
}

.jsonArray{
  padding: 0;
  .jsonArrayEntry {
    display: flex;
  }
}
