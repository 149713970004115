@import "../../../styles/mixins";

.canelInfo {
  @include section();
  border: none;
  background: $crimson;
  color: $white50;
  text-align: left;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  h4 {
    color: $white;
    margin: 0;
    font-size: 2.5rem;
  }
}
.orderSummary {
  @include section();
  border: none;
  font-size: 2rem;
  background: $black;
  color: $white;
  font-weight: 500;
  letter-spacing: 0.05em;

  &.orderCanceled {
    background-color: $lightGray;
    color: $black;
  }
  @media print {
    background: $white;
    color: $black;
  }
}

.orderVouchers {
  @include section();
  border: none;
  font-size: 2rem;
  background: $black;
  color: $white;
  font-weight: 500;
  letter-spacing: 0.05em;
  background-color: $lightGray;
  color: $black;
}

.subHeader {
  text-align: left;
  color: $black50;
  margin-top: -2rem;
  margin-bottom: 5rem;
  font-size: 3rem;
}
.orderGroup {
  padding-bottom: 3rem;
  hr {
    opacity: 0.5;
    border: 0;
    border-top: 1px dashed #FFFFFF;
    margin: 44px 0;
  }
  @media print {
    width: 100%;
    hr {
      border-color: #666;
    }
  }
}
.orderItem {
  @include flex-between();
  margin-bottom: 1em;
  font-size: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }
  > div {
    line-height: 1.5em;
    display: flex;
    align-items: center;
  }
  .quantityCol {
    flex: 0 0 2.5em;
    svg {
      vertical-align: middle;
    }
  }
  .nameCol {
    flex: 1 1 50%;
    span {
      font-size: 0.8em;
      margin-left: 1em;
    }
    .macro {
      font-size: 0.6em;
      text-align: left;
      opacity: 0.5;
      line-height: 1;
    }
  }
  .priceCol {
    flex: 1 0 20%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  span {
    text-align: left;
    opacity: 0.5;
  }
  big {
    font-size: 1.2em;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    font-size: 2rem;
    span {font-weight: 400; margin: 0;}
    .nameCol {
      line-height: 1;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      span {
        margin-left: 0;
      }
    }
    .priceCol {
      line-height: 1;
      flex-direction: column;
      align-items: flex-end;
      span { font-size: 0.8em}
    }
    svg {
      height: 1em;
    }
  }
  a {
    color: white;
  }
  @media print {
    flex-wrap: nowrap;
    width: 100%;
    margin: 1rem 0;
    padding: 2rem 0;
    border-bottom: dotted 1pt #666;
  }
}

.actionsSection {
  @include not-printable();
  display: flex;
  button {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      margin: 1rem 0 !important;
      font-size: 2rem;
    }
  }
}

.backLink {
  color: $orange;
  font-size: 2rem;
  line-height: 1.5em;
  font-weight: 500;
  margin-top: -2em;
  margin-bottom: 1em;;
  display: flex;
  img {
    margin-right: 0.5em;
  }
  &:hover {
    text-decoration: underline;
  }
}

.macroSummary {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  div {
    display: flex;
    flex: 1 1 50%;
    flex-direction: row;
    justify-content: space-between;
  }
  span {
    opacity: 0.7;
  }
  button {
    font-weight: 500;
    font-size: 1.8rem;
    color: $orange;
    background-color: $black;
    padding: 0;
    &:before {
      content:  url("./chevron.svg");
      margin-right: 5px;
    }
  }
}
