.socialSection {
  margin-bottom: 5rem;
}

.socialLinks {
  display: flex;
}

a.socialLink, a.socialLink:visited{
  color: black;
  text-decoration-line: underline;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  margin-right: 4rem;
  img {
    margin-right: 1.5rem;
  }
}

.appLinks {
  display: flex;
  > * {
    margin-right: 4rem;
  }
}
