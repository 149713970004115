@import "../../styles/colors";

.tagWrapper {
  display: flex;
  align-items: center;
  &.invert {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 150px;
    .tagLabel {
      flex: 1 0 auto;
    }
  }
}
.tagIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 4rem;
  height: 4rem;
  background: url('/icons/filter-bg.svg') center center no-repeat;
  background-size: contain;
  margin: 0 1rem;
}
.tagLabel {
  font-weight: 500;
}