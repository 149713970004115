@import "../../../styles/mixins";

.iconParagraph {
    display: flex;
    align-items: center;
    padding: 25px 0;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        .text {
            text-align: center;
            p {
            @include mob-con-p();
            }
        }
        .pic {
            margin: 24px 0;
        }
    }
    
}
.text {
    text-align: left;
}
.pic {
    display: flex;
    flex:  0 0 20%;
    justify-content: flex-end;
    margin-right: 44px;
    img {
        max-width: 100%;
    }
}