@import "../../../styles/mixins";

.contentHeader {
  display: block;
  position: fixed;
  background: $white;
  align-items: center;
  z-index: 8000;
  top: 0;
  left: 0;
  right: 0;
}
.wrapper {
  @include flex-between();
  @include wrapper(3rem);
  flex: 0 1 100%;
  margin: 0 auto;

}
.logo {
  display: block;
  width: 170px;
  height: 65px;
  background-image: url("/pobudka_logo.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 768px) {
    width: 108px;
    height: 48px;
}
}
.link {
    cursor: pointer;
    margin-right: 36px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navWrapper {
  display: flex;
  nav {
    display: flex;
  }
  .link {
    color: $black;
    opacity: 0.7;
    text-decoration: none;
    img {
      margin-right: 8px;
    }
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }
}
a.btnOrder {
  display: inline-flex;
  align-items: center;
  align-self: center;
  background: $orange;
  color: $white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1em 1.5em;
  border-radius: 1em;
  text-decoration: none;
  &:hover {
    background-color: black;
  }
}
.hamburger {
    font-size: 2rem;
    width: 5rem;
    height: 5rem;
    border-radius: 1em;
    background: $lightGray;
    margin-left: 1rem;
    border-radius: 1em;
    align-items: center;
    justify-content: center;
    display: none;
    span {
      font-size: 1.6em;
      font-weight: 100;
    }
}
.btnOrderMobile {
    display: none;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }
    .navWrapper {
        display: flex;
        nav {
          display: none;
          flex-direction: column;
          position: fixed;
          padding: 2rem 2rem;
          top: 0;
          left: 0;
          right: 0;
          background: $white;
          box-shadow: 10px 10px 100px $black20;
          z-index: 9000;
          &.open {
              display: flex;
          }
          .hamburger {
              position: absolute;
              top: 2rem;
              right: 2rem;
          }
        }
    }
    .link {
        font-size: 20px;
        padding: 12px;
        margin-right: 0;
        img {
            order: 2;
        }
    }
    a.btnOrderMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px -24px -24px;
        background: $orange;
        padding: 24px 36px;
        color: $white;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;

    }

}
