@import "../../../styles/mixins";

.labelValue {
  margin: 1rem 0;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 14px;
  line-height: 17px;
}
.label {
  color: $black50;
}
.value {
  margin-top: 1rem;
  color: $black;
  white-space: normal;
  word-wrap: normal;
}
