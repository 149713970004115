@import '../../../styles/mixins';

.orderItem {
    padding: 2.5rem;
    border: solid 1px $lightGray;
    border-radius: 2rem;
    margin-bottom: 2.5rem;
    position: relative;
    @include flex-between();
    font-size: 2rem;
    color: $black50;
    font-weight: 500;
    transition: all 0.3s ease;
    &:hover {
        background-color: $lightGray;
        border-color: $black10;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }    
    @media print {
        display: block;
    }
}
.orderMain {
    text-align: left;
    white-space: normal;
    flex: 0 1 275px;
    h3 {
        font-size: 20px;
        margin: 0 1rem 0 0;
        color: $black;
        text-transform: capitalize;
    }
    @media (max-width: 768px) { 
        flex: 1 1 auto;
    }
    @media print {
        display: block;
        width: 100%;
    }    
}
.orderSide {
    flex: 1 1 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &.canceled {
        opacity: 0.7;
    }
    span {color: $crimson;}
    div {
        flex: 0 0 160px;
    }
    @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 3rem;
        div {
            text-align: left;
            flex: 0 0 50%;
            &:last-of-type {
                order: -1;
            }
        }
    }    
}
a.btn {
    display: flex;
    background: $black;
    border-radius: 2rem;
    padding: 2rem 3rem;
    color: $white;
    &:hover {
        color: $orange;
    }
    @media (max-width: 768px) {
        margin-top: 7rem;
     }

}
.chevron {
    position: absolute;
    right: 1rem;
    top: 50%;
    padding: 1rem;
    transform: translateY(-50%);
    &:after {
    content: " ";
    display: block;
    width: 9px;
    height: 9px;
    border-color: $black50;
    border-style: solid;
    border-width: 0 0 3px 3px;
    transform-origin: 50% 50%;
    margin: 0 4px 0 0;
    transform: rotate(-135deg);
    }
    :hover > &:after {
            border-color: $black;
        
    }
}