@import "../../../styles/colors";
@import "../../../styles/vars";
@import "../../../styles/mixins";

.orderSummay {
  padding: 3rem 0 3rem;
  header {
    border-radius: 12px 0 0 12px;
    background: $black;
    margin-right: -5rem;
    padding: 30px 4rem 30px 26px;
    h3 {
      margin: 0;
      font-size: 16px;
      line-height: 19px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
  @media (max-width: 768px) {
    padding: 22px 0 22px;
    header {
      margin-right: -2rem;
      padding: 22px;
    }
  }
}
.summaryDetails {
  padding: 3rem;
  div {
    font-size: 2.5rem;
    @include flex-between();
    margin-bottom: 1.2em;
    em {
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1;
    }
    span span {
        color: $black50;
    }
  }
  @media (max-width: 768px) { 
    padding: 20px;
    div {
      font-size: 2rem;
    }
  }
}
.voucherTotal {
    color: $orange;
    &:before {
        content: '- ';
    }
}

.spbWrapper {
  @include flex-between();
  margin-right: -3rem;
  margin-top: 2rem;
  margin-left: 3rem;
  label {
      font-size: 2rem;
      color: $black50;
      font-weight: 500;
      margin-right: 3rem;
      margin-bottom: 3rem;
      line-height: 1.2em;
      flex: 1 1 50%;
  }
  @media (max-width: 768px) {
      margin-right: 0;
      flex-direction: column;
      align-items: stretch;
      label {
          margin-bottom: 2rem;
      }
  }
}