@import "../../../styles/mixins";

.feature {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.featureContent {
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  p {
    font-size: 2rem;
  }
}
p.hours {
  color: $black50;
  font-size: 0.8em;
  b {
    white-space: nowrap;
  } 
}
.featureImg {
  height: 1px;
  width: 100%;
  padding-top: 66%;
  max-width: 300px;
  margin: 0 auto;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  &.bag {
    background-image: url("./bag.svg");
  }
  &.cal {
    background-image: url("./cal.svg");
  }
  &.delivery {
    background-image: url("./delivery.svg");
  }
}
