@import "../../styles/mixins";

.deliveryInfo {
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 2rem;
  line-height: 1.1875em;
  section {
    margin: 0 0 4rem 0;
  }
  div {
    margin-bottom: 1rem;
  }
}

.fieldBig {
  font-size: 2.5rem;
  font-weight: 700;
}

.fieldMedium {
  font-size: 18px;
  font-weight: bold;
	white-space: normal;
}

.notes {
  color: $black50;
  font-size: 14px;
  white-space: normal;
}
