@import '../../../styles/colors';
@import '../../../styles/vars';
@import '../../../styles/mixins';

.dayHeader {
    background: $black;
    border-radius: 12px;
    padding: 30px 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 17px;
        padding: 22px;
    }

}

.dayItems {
    padding-left: 30px;
    @media (max-width: 768px) {
        padding-left: 0;
    }
}
.daySummary {
    @include flex-between();
    padding: 2rem 3rem 3rem;
    font-size: 2.5rem;
    font-weight: 500;
    em {
        font-size: 1.2em;
        font-weight: bold;
        line-height: 1;
    }
    margin-bottom: 3rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
}
.daySubSummary {
    @include flex-between();
    padding: 1rem 3rem 1rem;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1;
    color: $black75;
    >:first-child {
      font-weight: 400;
    }
    span {
      opacity: 0.7;
    }

    button {
      font-weight: 500;
      font-size: 1.8rem;
      color: $orange;
      background-color: $white;
      padding: 0;
      &:before {
        content:  url("./chevron.svg");
        margin-right: 5px;
      }
    }

    margin-bottom: 0.1rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
}
