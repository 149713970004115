@import "../variables.scss";

$padding: 18px;

.component {
  @include showFocused();
  display: inline-flex;
  &.fill {
    width: 100%;
    > input {
      width: 100%;
    }
  }
}

.input {
  display: inline-block;
  height: $heightNormal;
  border: 1px $colorNeutral solid;
  border-right: none;
  box-sizing: border-box;
  border-radius: $radius 0 0 $radius;
  font-family: $fontFamily;
  padding: 0 $padding;
  font-size: 12px;
  color: $colorText;
  font-weight: 500;
  outline: none;
  flex-grow: 1;
  margin: 0;
  background-color: $colorWhite;
  &::placeholder {
    opacity: 0.5;
    font-weight: normal;
  }
  &:disabled {
    background-color: $colorDisabled;
  }
  &:focus {
    border: 1px $colorNeutral solid;
    border-right: none;
  }
}

.upDownButtons {
  display: flex;
  flex-direction: column;
  border: 1px $colorNeutral solid;
  outline: none;
  border-left: 0;
  border-right: 0;
  justify-content: center;
  background: $colorWhite;

  > button {
    opacity: 0;
    background: $colorWhite;
    color: $colorNeutral;
    outline: none;
    border: none;
    cursor: pointer;
    transition: opacity $defaultTransitionDuration ease;
    padding: 0 5px;
    border: none;
    margin: 0;
    &:hover {
      color: darken($colorNeutral, 30);
    }
  }

  &.disabled {
    background-color: $colorDisabled;
    > button {
      &:hover {
        cursor: auto;
      }
    }
  }
  &.readOnly {
    > button {
      pointer-events: none;
    }
  }
}

.component:hover .upDownButtons:not(.disabled) button {
  opacity: 1;
}
