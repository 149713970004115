@import '../../styles/mixins';

.pageWrapper {
    @include wrapper();
    margin: 104px auto;
    font-size: 1.5rem;
    position: relative;
    @media (max-width: 768px) {
        padding-top: 6rem;
        margin: 2rem auto;
    }

}