@import "../../styles/colors";

.loginForm {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  @media (max-width: 460px) {
    width: 100vw;
  }
  a {color: $orange;}
  p {
    &:global(.uppercase) {
      font-weight: 500;
    }
  }
  :global(.uppercase) {
    text-align: center;
  }
  :global(.small) {
    font-size: 12px;
  }
  button {
    text-align: center;

    justify-self: center;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
}
.registered {
  display: flex;
  height: 60vh;
  max-height: 400px;
  flex-direction: column;
 .sentActivation {
   flex: 1 1 50%;
   margin-top: 3rem;
   margin-left: -3rem;
  background: url('/images/send-hand.svg') left center no-repeat;
  background-size: auto 80%;
 }
}

.reset {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.setPassword {
  margin-top: 8rem;
  display: flex;
  height: 30vh;
  max-height: 400px;
  flex-direction: column;
 .sentActivation {
   flex: 1 1 50%;
   margin-top: 3rem;
   margin-left: -3rem;
  background: url('/images/send-hand.svg') left center no-repeat;
  background-size: auto 80%;
 }
}
