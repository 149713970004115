@import '../../styles/colors';

@mixin count-editor() {
    background: $white;
    color: $black;
    display: flex;
    align-items: center;
}

.countEditor {
    @include count-editor();
    border-radius: 2.5rem;
    padding: 1.5rem 1rem;
    font-size: 2.5rem;
    z-index: 600;
}
.countEditorSmall {
    @include count-editor();
    border-radius: 1rem;
    padding: 0 1rem;
    font-size: 2rem;
    .counterBtn {
        padding: 1rem;
        font-size: 14px;
        line-height: 17px;
    }
}
.counterBtn {
    appearance: none;
    display: flex;
    font-weight: bold;
    padding: 0 2rem;
    color: $black50;
    user-select: none;
    background-color: transparent;
    transition: all 0.3s ease;
    font-size: 2.5rem;
    outline: none;
    cursor: pointer;
    &:hover {
        color: $black;
        transform: scale(1.5);
    }
    &:disabled {
      transform: none;
      color: $black50;
      opacity: 0.4;
      cursor: auto;
    }
}
.counterValue {
    display: flex;
    width: 1em;
    font-weight: bold;
    justify-content: center;
}

.limitTooltip {
    position: absolute;
    right: -8px;
    top: 40px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    padding: 16px 40px 16px 20px;
    color: $white;
    z-index: 1400;
    background: url('/icons/sadface.svg') 92% center no-repeat  $black;
    img {
        margin-left: 8px;
    }
}