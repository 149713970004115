$orange: #F39200;
$orange50: rgba(242, 146, 0, 0.35);
$orange35: rgba(242, 146, 0, 0.35);
$orange10: rgba(242, 146, 0, 0.1);
$orange70: rgba(242, 146, 0, 0.7);

$crimson: #DA1852;
$crimson10: rgba(218, 24, 82, 0.1);

$lightGreen: #74c32a;

$white: #fff;
$white50: rgba(255,255,255,0.5);

$black: #000;
$black10: rgba(0,0,0,0.15);
$black15: rgba(0,0,0,0.15);
$black20: rgba(0,0,0,0.2);
$black30: rgba(0,0,0,0.3);
$black50: rgba(0,0,0,0.5);
$black75: rgba(0,0,0,0.75);

$gray: #5B5B5B;
$lightGray: #F3F3F3;

$shadow: rgba(0,0,0,0.45);
$shadowHover: rgba(136, 82, 0, 0.45);

$fbBlue: #3578EA;

