@import "../../../styles/colors";
@import "../../../styles/mixins";
@import '../../../styles/vars';

.errorWrapper {
  color: $crimson;
  text-align: left;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  li:before {
    font-family: Arial,
      sans-serif;
  }
}
