@import '../../../styles/colors';


.smallBtn {
    background: $white;
    color: $black50;
    display: flex;
    align-items: center;
    padding: 1rem; 
    border-radius: 1rem;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    &:hover {
        color: $crimson;
    }
}