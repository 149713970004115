@import '../../../styles/colors';
@import '../../../styles/vars';
@import '../../../styles/mixins';

.pic {
    width: 100%;
    height: 250px;
    background: url('./congrats2.svg') center top no-repeat;
    background-size: 80% auto;
}
.ok {
    width: 100%;
    height: 250px;
    margin-left: -3rem;
    background: url('./congrats.svg') bottom left no-repeat;
    background-size: 90% auto;
}
.centerBtn {
 text-align: center;
}