@import "src/components/admin/variables.scss";

.links {
  margin: 5px 20px;
  a {
    color: $colorText;
    padding: 5px 20px;
    margin: 15px;
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px solid $colorPrimary;
    &.active {
      border-bottom-width: 3px;
    }
  }
}

$navHeight: 35px;
$sidenavWidth: 500px;

.container {
  position: absolute;
  top: $navHeight;
  left: 0;
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  max-width: calc(100vw - 20px);
  width: calc(100vw - 20px);
  overflow: hidden;
}

.listContainer {
  flex: 1;
  height: calc(100vh - #{$navHeight});
  max-height: calc(100vh - #{$navHeight});
  overflow: auto;
  &.sidenavOpen {
    max-width: calc(100vw - #{$sidenavWidth});
  }
}

.detailsContainer {
  border-top: 1px solid #eee;
  padding-top: 20px;
  min-width: $sidenavWidth;
  max-height: calc(100vh - #{$navHeight});
  overflow-y: scroll;
  margin-left: 15;
  box-shadow: 0 10px 10px #aaa;
  // padding: 5px;
  position: relative;
  padding: 5px;
  box-sizing: border-box;
}
