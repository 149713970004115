.notPaid td{
  background-color: #ff0b0b2b;
  border: 1px solid #ab050563;
  border-left: none;
  &:first-of-type {
    border-left: 1px solid #ab050563;
  }
}

.canceled td{
  background-color: #52060673;
  border: 1px solid #ab050563;
  border-left: none;
  &:first-of-type {
    border-left: 1px solid #ab050563;
  }
}
