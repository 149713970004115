@import "../../styles/mixins";

.nav {
  margin: 3rem 0 6rem 0;
  justify-content: flex-start;
  a {
    font-size: 3rem;
    margin-right: 3rem;
  }
}

.logo {
  display: block;
  width: 170px;
  height: 65px;
  background-image: url("/pobudka_logo.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}

.header {
  @include section-header();
  letter-spacing: 0.05em;
  text-align: left;
  font-size: 2rem;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 1rem 0;
  }

  span {
    opacity: 0.7;
    font-weight: 500;
  }
}
.sideHeader {
  background: $black;
  padding: 2rem;
  border-radius: 2rem;
  color: $white;
  h2 {
    margin: 0.5em 0;
  }
}
.accountContent {
  padding-bottom: 6rem;
}

a.balanceNavLink {
  margin-right: 0;
}

.balanceValue {
  margin-left: 1.5rem;
  line-height: 1em;
  font-size: 2rem;
  border-radius: 1rem;
  color: $black;
  display: inline-flex;
  padding: 4px 10px;
  background: $lightGray;
  :global(.highlight) + & {
    background: $black;
    color: $white;
  }
}
h1 {
  .balanceValue {
    vertical-align: middle;
  }
}
nav {
  :global(.highlight) {
  .balanceValue {
    background-color: transparent;
    color: $black;
  }
}
}

.modalInfo {
  text-align: left;
  color: $black50;
  p {
    font-size: 14px;
    margin-bottom: 1.5em;
  }
}
.payoutAction {
  display: flex;
  justify-content: center;
  padding: 3rem;
  button {
    transform: scale(1.25);
  }
}
.caption {
  text-transform: uppercase;
  color: $black50;
  font-size: 2rem;
  line-height: 1em;
  display: block;
}
