@import '../variables.scss';

$padding: 18px;

.textArea {
  height: $heightNormal;
  border: 1px $colorNeutral solid;
  box-sizing: border-box;
  border-radius: $radius;
  font-family: $fontFamily;
  padding: $padding;
  font-size: 12px;
  min-height: 52px;
  color: $colorText;
  font-weight: 500;
  &::placeholder {
    opacity: 0.5;
    font-weight: normal;
  }
  &.fill {
    width: 100%;
  }

  &.noResize {
    resize: none
  }
  &:disabled {
    background-color: $colorDisabled;
  }

  @for $i from 1 through 10 {
    &[rows="#{$i}"] {
      min-height: 54px + ($i - 1)*14px;
    }
  }

}
