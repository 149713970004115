@import "../../../styles/colors";

.sideCTABtn {
    display: flex;
    background: $white;
    align-items: center;
    font-weight: bold;
    padding: 2rem 3rem;
    border-radius: 2rem;
    color: $black;
    font-size: 2rem;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;

    img {
        margin-left: 1rem;
    }
    &:hover:not([disabled]) {
        box-shadow: 1px 1px 3px 2px $shadowHover;
        color: $orange;
    }

  &:disabled {
    box-shadow: none;
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    span {
      display: none;
    }
    img {
      margin: 0;
    }
  }
}
