@import "../../styles/mixins";

.menuWrapper {
    @include wrapper();
    margin: 124px auto;
    font-size: 1.5rem;
    position: relative;
    background: #fff;
    @media (max-width: 768px) {
        max-width: 100vw;
        overflow-x: hidden;
        margin: 260px 0;
        > div:first-child {
            margin-top: -150px;
        }
    }
    div[name] {
        padding-top: 1px;
    }
}