@import "../../../styles/mixins";

.thirdImage {
  @include flex-between();
  align-items: center;
  padding: 3rem 0;
 
  &.invert {
    .text {
      order: 2;
    }
    .pic {
      justify-content: flex-start;
    }
  }
  :global(.h2) {
    color: $black50;
    margin: 0 0 24px 0;
  }

  @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin-bottom: 6rem;
      .text {
        order: 2;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;        
      }
      h1 {
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;  
        margin-top: 30px;        
      }
  }

}
.text {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  flex: 0 1 60%;
  p {
    font-size: 2.5rem;
    line-height: 1.6em;
    letter-spacing: 0.1em;
    word-spacing: 0.22em;
    padding-right: 2em;
    @media (max-width: 768px) {
      padding: 0 1rem;
      @include mob-con-p();

    }
}  
}
.pic {
  display: flex;
  flex: 0 1 30%;
  justify-content: flex-end;
  img {
    width: 300px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    img {
      max-width: 80%;
    }
  }
}
