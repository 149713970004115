@import '../variables.scss';

.rightActions{
  display: inline-flex;
  align-items: center;
  background: $colorWhite;

  box-sizing: border-box;
  height: $heightNormal;
  border-radius: 0 $radius $radius 0;
  border: 1px $colorNeutral solid;
  border-left: none;
  &:not(:empty) {
    padding-right: 5px;
  }
  &:empty {
    min-width: 3px;
  }
  > * + * {
    margin-left: 5px;
  }
  &.disabled {
    background-color: $colorDisabled;
  }
  &.disableActions > * {
    pointer-events: none;
    opacity: 0.5;
  }
  &.multiline {
    height: auto;
  }
}
