@import '../../../styles/colors';
@import '../../../styles/mixins';

.fieldWrapper {
    border-radius: 6px;
    background-color: $lightGray;
    transition: all 0.3s ease;
    position: relative;
    display: flex;   
    align-items: center;
    justify-content: stretch;
    margin: 0 3rem 3rem 0;
    width: 100%;
    color: $black30;
    &:focus-within {
        .fieldLabel {
            top: 20px;
        }
    }
}

input.fieldInput, 
textarea.fieldInput {
    display: flex;
    padding: 27px 20px 13px 20px;
    width: 100%;
    margin: 0;
    border-radius: 6px;
    font-family: Rubik;
    color: $black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;
    z-index: 10;
    background: transparent;
    outline: none;   
    &:active, &:focus {
        background: none;
        box-shadow: 0px 0px 2px $orange35;
    }
    &:disabled {
        color: $white;
    }    
}
label.fieldLabel {
    font-family: Rubik;
    z-index: 5;
    color: inherit;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1em;
    position: absolute;
    top: 50%;
    left: 20px;
    right: 20px;
    transition: all 0.2s ease;
    transform: translateY(-50%);
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    :active + &, :focus + &, .fieldActive &, .fieldDisabled &, [value]:not([value=""]) + & {
        font-size: 10px;
        top: 20px;
    }
    input:-internal-autofill-selected + & {
        top: 20px;
    }
}
.fieldActive {
    color: $black20;
}
.fieldDisabled {
    background: $gray;
    color: $white50;
}
.fieldInvalid {
    color: $crimson;
    background-color: $crimson10;
    input.fieldInput {
        color: $crimson;
    }
}
:global(img.help) {
    margin-right: 16px;
    cursor: help;
}

:global(img.help) + div {
    display: none;
    opacity: 0;
    transition: all 0.3s ease;
}
:global(img.help):hover + div {
    display: block;
    opacity: 1;
}