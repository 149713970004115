@import '../../styles/colors';
@import '../../styles/vars';

@mixin menu-list {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    column-gap: 8rem;
    row-gap: 5rem;
}

.menuItemsList {
    @include menu-list();
    @media (min-width: $mobile) {
        margin: 0;
        display: grid;
        grid-template-columns:  repeat(2, minmax(0, 1fr));
    }
    @media (min-width: $tablet) {
        grid-template-columns:  repeat(3, minmax(0, 1fr));
    }

}
.menuSetsList {
    @include menu-list();
    @media (min-width: $desktop) {
        margin: 0;
        display: grid;
        grid-template-columns:  1fr 1fr;
    }
}
.sectionTitle {
    text-align: left;
    color: $black50;
    opacity: 0.4;
    margin-top: 10rem;
    @media (max-width: 768px) {
        text-align: center;
    }
    :global(.xmas) & {
        color: $crimson;
        opacity: 1;
        &:after {
            content: ' ';
            background: url('./special.svg') center no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: bottom;
            width: 2em;
            height: 1.5em;
        }
    }
    :global(.easter) & {
        color: $lightGreen;
        opacity: 1;
        &:after {
            content: ' ';
            background: url('./easter.svg') center no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: bottom;
            width: 2em;
            height: 1.5em;
        }
    }
    :global(.help) & {
      background: linear-gradient(-180deg, #0057b7 57%, #ffd700 43%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 1;
    }
}
