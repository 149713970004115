@import '../../styles/colors';

@mixin menu-item() {
  padding: 6rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  border-bottom: dashed 1pt $black20;
  position: relative;
}

.header {
  position: relative;
  border-radius: 3rem;
  background-color: $black;
  color: $white;
  height: 18rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
  justify-content: flex-end;

  a {
    z-index: 600;
  }

  b {
    font-size: 2.5rem;
    text-align: left;
    max-width: 50%;
    white-space: pre-wrap;
    z-index: 600;
  }

  &:hover {
    .front {
      animation: tagBend 0.6s ease;
    }
  }
}

.shade {
  z-index: 10;
  height: 26rem;
  position: absolute;
  left: -5rem;
  top: -8rem;
  cursor: pointer;
  filter: drop-shadow(4px 20px 40px $shadow);

  @media (max-width: 768px) {
    height: 180px;
    left: -4rem;
    top: -6rem;
  }
}

.menuItem {
  @include menu-item();
}

.selectedMenuItem {
  @include menu-item();

  .header {
    background-color: $orange;
  }

  .shade {
    filter: drop-shadow(4px 20px 40px $shadowHover);
  }
}

.unavailableMenuItem {
  @include menu-item();
  filter: grayscale(1);
  opacity: 0.4;
}

.addBtn {
  position: relative;
  text-decoration: none;
  border-radius: 2.5rem;
  border: dashed 1px $white50;
  color: $white;
  font-weight: 500;
  font-size: 2.5rem;
  display: flex;
  padding: 2rem 2.5rem 2rem 6rem;
  background: url('/icons/basket-white.svg') 2rem center no-repeat $black50;
  cursor: pointer;

  &:hover {
    border-style: solid;
    color: $black;
    background: url('/icons/basket.svg') 2rem center no-repeat $white;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.itemContent {
  text-align: center;
  margin: 0 auto;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    color: $black50;
    font-size: 2rem;
    margin-top: 1rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 19px;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  > div {
    margin-bottom: 1rem;
  }
}

.itemTag {
  position: absolute;
  top: -12px;
  right: 4rem;

  .back {
    display: block;
    width: 18px;
    height: 12px;
    position: absolute;
    left: -8px;
    top: 0;
    overflow: hidden;

    &:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 26px;
      background: #444;
      transform: skew(-30deg);
    }
  }

  .front {
    position: relative;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    line-height: 1.2em;
    background: #666;
    border-radius: 0 4px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease-in-out;
    box-shadow: -1px 0px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: -0.02em;
  }

  &.greenTag {
    .front {
      background: #4e8b43;
    }

    .back:after {
      background-color: #2b4d25;
    }
  }

  &.orangeTag {
    .front {
      background: $orange;
    }

    .back:after {
      background-color: $orange70;
    }
  }

  &.yellowTag {
    .front {
      background: #EFC900;
    }

    .back:after {
      background-color: #c9a804;
    }
  }

  &.redTag {
    .front {
      background: #da2f18;
    }

    .back:after {
      background-color: #992111;
    }
  }

  &.blueTag {
    .front {
      background: #22558c;
    }

    .back:after {
      background-color: #1f3c69;
    }
  }
  
}

@keyframes tagBend {
  0% {
    transform: skew(0) scaleY(1);
  }

  50% {
    transform: skew(10deg) scaleY(0.9);
  }

  100% {
    transform: skew(0deg) scaleY(1);
  }
}
