@import "../../styles/mixins";

@mixin  section {
  @include wrapper();
  margin: 0 auto;
}

.landingPage {
  background: #ccc;
}

.recommended {
  @include section();
  
}
.bannerSection {
  @include section();
}
.featureSection {
  @include section();
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 64px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  + .recommended {
    margin-top: -7rem;
  }
}
