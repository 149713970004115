@import '../../../styles/colors';

.basketBtn {
    position: relative;
    text-decoration: none;
    border-radius: 1em;
    color: $white;
    font-weight: bold;
    font-size: 1.5rem;
    display: inline-flex;
    padding: 1em 1em 1em 3em;
    background: url('/icons/basket-white.svg') 1em center no-repeat $orange;
    background-size: 2rem auto;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-color: $black;
        .priceTooltip {
            opacity: 1;
        }
    }
    @media (max-width: 768px) {
        border-radius: 16px;
        font-size: 2rem;
        padding: 1rem 1.5rem 1rem 4rem;
        background-position: 1.5rem center;
        background-size: 2rem;
        display: flex;
        align-items: center;
      }
}
.priceTooltip {
    transition: all 0.4s ease-in-out;
    opacity: 0;
    background:  $black;
    border-radius: 1em;
    padding: 1em;
    color:$white;
    position: absolute;
    top: 100%;
    margin-top: 4px;
    right: 0;
    z-index: 888;
    &:global(.show) {
        opacity: 1;
    }
    @media (max-width: 768px) { 
        font-size: 2rem;
        padding: 1rem 1.5rem;
    }

    &:before {
        content: ' ';
        display: block;
        background: $black;
        width:8px;
        height: 8px;
        position: absolute;
        top: -1px;
        right: 1em;
        transform: rotate(45deg) translateX(-50%);        
    }
}