@import '../../styles/mixins';

.columnsWrapper {
    display: flex;
    align-items: flex-start;    
    font-size: 1.5rem;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        &.invert {
            flex-direction: column;
        }
    }
    @media print {
        flex-direction: column-reverse;
        width: 100%;
        justify-content: stretch;
    }
}

.sideColumn {
    width: 300px;
    flex: 0 0 300px;
    order: 2;
    margin-left: 3rem;
    @media (max-width: 768px) { 
        flex: 1 1 auto;
        width: 100%;
        margin: 0 0 2rem 0;        
    }
    @media print { 
        width: 100%;
    }
}

.mainColumn {
    flex: 1 1 70%;
    @media (max-width: 768px) {
        width: 100%;
    }
    @media print { 
        width: 100%;
    }

}