$vMargin: 20px;
$hMargin: 10px;

$hMarginMove: -$hMargin;

.row {
  display: flex;
  margin: $vMargin 0;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.row.spacing {
  > * + * {
    margin-left: 15px;
  }
}

.nowrap {
  flex-wrap: nowrap;
}

.column .row {
  margin: $vMargin/2 0;
  width: 100%;
}

@mixin width($g, $margin, $offset) {
  flex-grow: unset;
  min-width: calc(#{(100 / 16 * $g) * 1%} - #{$margin} - #{$offset});
}

.column {
  display: flex;
  @for $u from 1 through 16 {
    &.width#{$u} {
      @include width($u, 2 * $hMargin, var(--offset));
    }
  }
  margin: 0 $hMargin;
  flex-direction: column;
  position: relative;
}

.spacer {
  flex-grow: 1;
  @for $u from 1 through 16 {
    &.width#{$u} {
      @include width($u, 0px, 0px);
      flex-grow: unset;
    }
  }
}

.row.nomargin {
  margin: 0;
  > :first-child {
    margin-left: 0;
  }

  > :last-child {
    margin-right: 0;
  }
}
