@import "../variables.scss";

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 1.3rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$datepicker__item-size: 3.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

@import "../../../../node_modules/react-datepicker/src/stylesheets/datepicker-cssmodules.scss";

.component {
  @include showFocused();
  display: inline-flex;
  text-align: left;
  &.fill {
    width: 100%;
    > :first-child {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }

  :global(.react-datepicker__input-container) {
    display: inline;
  }
  :global(.react-datepicker-wrapper) {
    display: flex;
    height: $heightNormal;
    border: 1px $colorNeutral solid;
    border-right: none;
    box-sizing: border-box;
    border-radius: $radius 0 0 $radius;
    font-weight: 500;
    outline: none;
    font-family: $fontFamily;
    color: $colorText;
    align-items: center;

    input {
      font-family: $fontFamily;
      font-size: 12px;
      color: $colorText;
      font-weight: 500;
      outline: none;
      border: none;
      margin: 0;
      background-color: white;
    }
    button {
      outline: none;
    }
    :global(.react-date-picker__wrapper) {
      border: none;
      height: $heightNormal;
      padding: 0 10px;

      flex: 1;
    }
  }
}
