@import "../../styles/colors";
@import "../../styles/mixins";

.title {
  margin-bottom: 0;
  + h2 {
    margin-top: 0;
    color: $black50;
  }
}
.header {
  background: $black;
  color: $white;
  padding: 3rem;
  border-radius: 2rem;
  position: sticky;
  top: 15rem;

}
.section {
  @include section();
  border: solid 1px $black10;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: stretch;
  align-items: stretch;
  h3 {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    color: $black50;
    margin: 4rem 0;
    &:first-child {
      margin-top: 0;
    }
  }
  nav {
    font-size: 3rem;
    justify-content: flex-start;
    margin-bottom: 7rem;
    a {
      margin-right: 1em;
    }
  }
  p {
    font-size: 18px;
    color: $black50;
  }
}
.actions {
  margin-top: 2rem;
}
.content {
  text-align: left;
  h2 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.1rem;
  }
  p {
    font-size: 16px;
    margin: 0;
    opacity: 0.7;
  }
}
.row {
  display: flex;
  align-items: center;
  max-width: 64%;
  .row {
    flex: 1 0 50%;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    > div {
      margin-right: 0;
    }
    .row {
      flex: 1 1 auto;
      width: 100%;
    }
  }
}
.przelewy24 {
  margin-left: 1rem;
  margin-top: -4px;
}

.bigBtn {
  background: $orange;
  color: $white;
  font-size: 3rem;
  padding: 2.5rem 5rem;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 0.05em;
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.footer {
  margin: 0 auto;
  z-index: 300;
  position: sticky;
  padding: 15px 0;
  bottom: 0;
  background: white;
  font-size: 14px;
  line-height: 20px;
  color: $black50;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
  }
}
.columns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: stretch;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.sideColumn {
 width: 300px;
 flex: 0 0 30%;
 margin-left: 3rem;
 @media (max-width: 768px) {
  margin: 0 0 3rem 0;
  width: 100%;
 }
}
.mainColumn {
  flex: 0 1 70%;
}
