@import "../../../styles/mixins";

.halfImage {
    @include flex-between();
    align-items: center;
    padding: 6rem 0;
    background: url('/images/ameba1.svg') 80% center no-repeat;
    background-size: auto 110%;

    @media (max-width: 768px) {
        flex-direction: column;
        background-position: 60% 90%;
        background-size: 130% auto;
        padding: 30px 0;
    }

    &.invert {
        background: url('/images/ameba2.svg') 0% center no-repeat;
        background-size: auto 100%;

        .text {
            order: 2;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            background-position: 60% 10%;
            background-size: 120% auto;
            padding: 30px 0;
        }
    }

    +h1 {
        margin: 0;
    }
}

.shift {
    margin-top: -9rem;

    @media (max-width: 768px) {
        margin-top: -50px;
    }
}

.text {
    text-align: left;
    font-weight: 400;
    flex: 0 0 50%;
    padding-right: 2rem;

    p {
        font-size: 2.5rem;
        line-height: 1.6em;
        letter-spacing: 0.1em;
        word-spacing: 0.22em;
        padding-right: 2em;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 30px;

        p {
            padding: 0;
            @include mob-con-p();
        }
    }
}

.pic {
    display: flex;
    flex: 0 0 45%;
    justify-content: center;

    picture {
        border-radius: 5rem;
        overflow: hidden;

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    }

    img {
        max-width: 100%;
        margin-bottom: -3px;
    }

}