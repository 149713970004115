@import '../../styles/colors';
@import '../../styles/mixins';

.coockiesModal {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    margin-left: 3rem;
    max-width: 560px;
    border-radius: 3rem;
    background-color: $orange;
    color: #fff;    
    box-shadow: 2px 2px 30px $black20;
    z-index: 9000;
    &.long {
        max-width: 100vw;
        margin: 0;
        left: 3rem;
        background-color: #fff;
        color: $black75;
        .modalContent {
            display: flex;
            align-items: flex-end;
            p {
                margin: 0 3rem 0 0;
            }
            a {
                color: $black;
            }
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }
    }
}
.modalContent {
    padding: 3rem;
   p {
        font-weight: 300;
        font-size: 1.5rem;
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
}
