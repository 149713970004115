@import '../variables.scss';

.headerCell {
  padding: 0 8px;
  text-align: left;
  color: $colorText;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  &.minimal {
    white-space: nowrap;
    width: 1%;
    min-width: 1%;
  }
  &.centered {
    text-align: center;
  }
}

.cell {
  margin: 3px 0;
  height: 40px;
  font-size: 11px;
  background-color: $colorWhite;
  padding: 5px 20px;
  border: 1px solid $colorNeutral;
  border-left: none;

  &:first-of-type {
    border-radius: 3px 0 0 3px;
    border-left: 1px solid $colorNeutral;
  }
  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }

  &.minimal {
    white-space: nowrap;
    width: 1%;
  }
  &.centered {
    text-align: center;
  }
  > * + * {
    margin-left: 5px;
  }
}

.table{
  border-spacing: 0 3px;
  width: 100%;
}
