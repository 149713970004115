@import '../variables.scss';

.modal {
  box-shadow: 1px 4px 10px $colorGray5;
  border-radius: $radius;
  opacity: 1;
  min-height: 300px;
  background-color: $colorBackground;
  z-index: 100;

  &.small {
    width: 679px;
    max-width: 95vw;
    max-height: 95vh;
    min-height: 100px;
    overflow-y: auto;
  }

  &.medium {
    width: 900px;
    max-width: 95vw;
    max-height: 95vh;
    overflow-y: auto;
  }

  &.big {
    width: 1140px;
    max-width: 95vw;
    max-height: 95vh;
    overflow-y: auto;
  }

  &.large {
    width: 1500px;
    max-width: 95vw;
    max-height: 95vh;
    overflow-y: auto;
  }
}
