@import "../../styles/colors";
@import "../../styles/mixins";

@mixin btn {
  border-radius: 1em;
  display: inline-flex;
  align-items: center;
  padding: 1em 1.5em;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.17em;
  cursor: pointer;
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @include not-printable();
}

.section {
  @include section();
  border: solid 1px $black10;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: stretch;
  align-items: stretch;
  white-space: nowrap;
  page-break-inside: avoid;
  h3 {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    color: $black50;
    margin: 3rem 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &:global(.side) {
    padding: 2rem 3rem 3rem 3rem;
    margin-bottom: 3rem;
    h3 {
      margin: 2rem 0;
    }
  }
  nav {
    font-size: 3rem;
    justify-content: flex-start;
    margin-bottom: 7rem;
    a {
      margin-right: 1em;
    }
  }
  p {
    font-size: 18px;
    color: $black50;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 600px;
  &.full {
    max-width: 100%;
  }
  &.top {
    align-items: flex-start;
  }
  .row {
    flex: 1 0 50%;
  }
  > label {
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size: 12px;
    color: $black50;
    margin: 0 3rem 3rem 0;
    white-space: normal;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    > div {
      margin-right: 0;
    }
    .row {
      flex: 1 1 auto;
      width: 100%;
    }
  }
}
.btnRow {
  display: flex;
  margin: 3rem 0;
}

.btnBlack {
  @include btn();
  background-color: $black;
  color: $white;
  &:hover:not([disabled]) {
    color: $orange;
  }
}

.btnWhite {
  @include btn();
  background-color: $white;
  color: $black;
}
a.btnOrange, button.btnOrange {
  @include btn();
  background-color: $orange;
  color: $white;
}
.btnRed {
  @include btn();
  background-color: rgba(218, 24, 82, 0.05);
  color: $crimson;
  &:hover:not([disabled]) {
    color: $white;
    background: $crimson;
  }
}
.btnCrimson {
  @include btn();
  background-color: $crimson;
  color: $white;
  &:hover:not([disabled]) {
    color: $white;
    background: $crimson;
  }
}
.btn {
  + .btn {
    margin-left: 3rem;
  }
  text-decoration: none;
}

.voucherInput {
  border: none;
  padding: 2rem;
  background: rgba(242, 146, 0, 0.1);
  color: #000;
  font-size: 2rem;
  border-radius: 2.5rem;
  outline: none;
  width: 100%;  
  &::placeholder {
    color: $orange;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 1px $orange;
  }
  @media (max-width: 768px) {
    
    align-self: stretch;
    margin-right: 0;
  }
}
.voucherWrapper {
  position: relative;
  margin: 0 3rem 3rem 0;
  flex: 1 1 50%;
  button { 
    position: absolute;
    top: 0;
    right: 0;
    margin: 2px;
  }
  @media (max-width: 768px) { 
  }
}