@import "../../../styles/colors";
@import "../../../styles/mixins";

.heroSection {
  margin-top: 110px;
  background-image: linear-gradient(180deg, #ffffff 0%, #eaedf1 100%);
  background-position: bottom left;
  background-repeat: repeat-x;
  background-size: auto 70%;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
}
.heroOuter {
  background: url("/images/hero_bg.svg") center bottom repeat-x;
  background-size: auto 140px;
  padding-bottom: 120px;
  overflow: hidden;
  @media (max-width: 768px) {
      background-size: auto 30%;
      padding-bottom: 100px;
  }
}
.heroWrapper {
  @include wrapper();
  margin: 0 auto;
  position: relative;

}
.heroContent {
  p {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }
  }
}
.heroText {
  display: flex;
  min-height: calc(max(37vh, 300px));
  align-items: center;
  justify-content: center;
  margin: 0;
  h1 {
    // background: url("./img/arrow.svg") top left no-repeat;
    padding: 6rem 10rem;
    margin: 2rem 0 0 0;
    font-size: 5rem;
    line-height: 1.2em;
    color: $black50;
    text-align: right;
    em {
      color: $black;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 160px 0 60px;
  }

  @media (max-width: 768px) {
    margin: 0;
    h1 {
      // background: url("./img/arrow-mobile.svg") top left no-repeat;
      font-size: 24px;
      line-height: 32px;
      padding: 40px 0 120px;
    }
  }
}

@mixin heroImg {
  position: absolute;
  bottom: 5px;
  transform: scale(0.9);
  @media (max-width: 1150px) {
    bottom: 20px;
  }
  @media (max-width: 768px) {
    transform: scale(0.5);
    bottom: 55px;
  }
}

.leftImg {
  @include heroImg();
  left: -50px;
  transform-origin: left bottom;
  @media (max-width: 768px) {
    left: -30px;
  }
}

.rightImg {
  @include heroImg();
  right: -50px;
  transform-origin: right bottom;
  @media (max-width: 768px) {
    right: -30px;
  }
}
