@import "../../styles/colors";

.calendarWrapper {
  position: relative;
  display: flex;
  max-width: 100%;
  @media (max-width: 768px) {
    max-width: 100vw;
    margin: 0 -2rem;
    .dayCard:first-child {
      margin-left: 2rem;
    }

  }
}
.calendarSlider {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  @media (max-width: 768px) {
    overflow: auto;
    padding-right: 4rem;
  }

}

.dayCard {
  flex: 0 0 6.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  padding: 1rem 0;
  margin-right: 2rem;
  color: $black50;
  font-weight: 500;
  border: dashed 1px $black20;
  background: linear-gradient(180deg, #fff 50%, #000 50%);
  background-repeat: repeat-x;
  background-size: auto 210%;
  background-position: 0 0;
  transition: all 0.5s ease;
  outline: none;
  cursor: pointer;
  &:hover {
    border: solid 1px $black50;
    big {
      transform: scale(1.4);
    }
  }
  big {
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 1.5em;
    transition: all 0.3s ease;
    color: $black;
  }
  small {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}
.disabledCard {
  background-image: none;
  background-color: $lightGray;
  color: $black20;
  pointer-events: none;
  border: none;
  cursor: not-allowed;
  big {
    color: $black20;
  }
  .countBadge {
    display: none;
  }
}
.activeCard {
  border: solid 1px $black;
  background-position: 0 99%;
  color: $white;
  big {
    color: $white;
  }
}
.xMas {
  color: red;
  /* TODO */
}
.countBadge {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $orange;
  color: $white;
  top: 0;
  right: -1rem;
}

@mixin scroll-btn {
  display: flex;
  align-items: center;
  width: 12rem;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  @media (max-width: 768px) {
      display: none;
  }
}
.scrollLeft {
  @include scroll-btn();
  justify-content: flex-start;
  background: linear-gradient(90deg, #fff 15%, rgba(255,255,255,0) 100%);
  left: 0;
}
.scrollRight {
  @include scroll-btn();
  justify-content: flex-end;
  background: linear-gradient(90deg, rgba(255,255,255,0),#fff 85%);
  right: 0;
}
.hide {
  opacity: 0;
  pointer-events: none;
  button {
    pointer-events: none;
  }
}
.overlay{
  position:absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: all;
}
.priceTooltip {
  transition: all 0.4s ease-in-out;
  opacity: 1;
  position: fixed;
  background:  $black;
  border-radius: 1em;
  padding: 3rem;
  color:$white;
  position: absolute;
  top: 100%;
  margin-top: 4px;
  z-index: 8000;
  max-width: 421px;
  font-size: 1.7rem;
  line-height: 20px;
  font-weight: 700;

  @media (max-width: 768px) {
      font-size: 2rem;
      // padding: 1rem 1.5rem;
      width: 100%;
      left: 0;
      right: 0;
  }

  &:before {
      content: ' ';
      display: block;
      background: $black;
      width:8px;
      height: 8px;
      position: absolute;
      top: -1px;
      left: var(--element-pointer-x);
      transform: rotate(45deg) translateX(-50%);
  }
}
