@import "../../../styles/colors";

.scrollBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 2.5rem;
  background: rgba(242, 146, 0, 0.05);
  pointer-events: all;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;

  &:after {
    content: " ";
    display: block;
    width: 9px;
    height: 9px;
    border-style: solid;
    border-width: 0 0 3px 3px;
    border-color: $orange;
    transform-origin: 50% 50%;
    margin: 0 4px 0 0;
    transform: rotate(-135deg);
  }
  &.invert:after {
    transform: rotate(45deg);
    margin: 0 0 0 4px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
      background: $orange;
      &:after {
          border-color: $white;
      }
  }
}
