@import '../../../styles/colors';
@import '../../../styles/vars';
@import '../../../styles/mixins';

.dayDeliveryBox {
    @include order-irem-box();
}
.deliveryHeader {
 @include  order-item-header();
}
.deliveryDetails {
    @include flex-between();
    p {
        font-size: 2rem;
        color: $black50;
    }
}
.deliveryValue {
    font-size: 2.5rem;
    white-space: nowrap;
}