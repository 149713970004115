@import "../../styles/mixins";

.locationForm {
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 2rem;
  line-height: 1.1875em;
  section {
    margin: 0 0 4rem 0;
    &.actions {
      margin: 8rem 0 0 0;
    }
  }
  div {
    margin-bottom: 1rem;
  }
}

.fieldBig {
  font-size: 2.5rem;
  font-weight: 700;
  color: $black;
}

.field {
  color: $black50;
}

.fieldMedium {
  font-size: 18px;
  font-weight: bold;
  color: $black50;
}

.notes {
  color: $black50;
  font-size: 14px;
}
