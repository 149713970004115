@import "../../styles/colors";
@import "../../styles/mixins";

.pageHeader {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  justify-content: center;
  background: linear-gradient(#fff 95%, rgba(255,255,255,0));
  z-index: 8000;
  h4 {
    text-transform: uppercase;
    color: $black;
    font-weight: 500;
    font-size:2rem;
    line-height: 1em;
    margin-top: 0;

  }

  @media screen and (max-width: 768px) {
    padding: 1px;
    .logo {
      width: 108px;
      height: 42px;
    }
    .logoAndDate {
      padding: 2rem 0;
    }

  }
}

.headerGrid {
  @include wrapper();
  margin: 0 auto;
  display: grid;
  grid-template-columns: 5fr 250px;
  grid-template-rows: auto auto auto auto;
  @media (max-width: 768px) {
    grid-template-columns: 5fr 3fr;
    max-width: 100vw;
    padding: 0 2rem;
  }
}

.headerWrapper {
  @include wrapper();
  margin: 0 auto;
  > section {
    @include flex-between();
    max-width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0 2rem;
  }

}
.logoAndDate {
  display: flex;
  align-items: center;
  padding: 3rem 0;
}
.headerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

a.dateBtn {
  color: $white;
  font-weight: 500;
  background: $black;
  display: none;
  padding: 1rem;
  border-radius: 2rem;
  margin-left: 2rem;
  text-transform: uppercase;
}
.dayPicker {
  margin-bottom: 2rem;
  flex: 0 1 100%;
  max-width: 100%;
}
.navigation {
  margin-top: 4px;
  max-width: 100%;
  @media (max-width:768px) {
    max-width: 80%;
  }
}
.filters {
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1024px) {
    align-items: center;
  }
}

.pageHeaderSticky {
  background: #fff;
  position: fixed;
  margin:0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8000;
  .dateBtn {
    display: flex;
  }

  .logoAndDate {
    padding: 2.5rem 0;
  }
  .dayPicker {
    display: none;
  }
  .navigation {
    padding-bottom: 2.5rem;
  }
  .filters {
    display: none;
  }
  h4 {
    display: none;
  }

  @media screen and (max-width: 738px) {
    padding: 1px;
    .navigation {
      max-width: 100%;
    }
  }

}
