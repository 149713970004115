@import '../variables.scss';

$animationTime: 0.3s;
.overlay {
  display: flex;
  height: 0;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 0;
  &.active {
    animation: show $animationTime forwards;
    overflow: auto;
  }
  &.noactive {
    animation: hide $animationTime forwards;
  }
  &.light {
    background-color: rgba($colorWhite, 0.7);
  }
  &.dark {
    background-color: rgba($colorBlack, 0.7);
  }
  &.transparent {
    background-color: transparent;
  }
  &.page {
    height: 0;
    width: 100vw;
    position: fixed;
  }
}

@keyframes show {
  0% {
    opacity: 0
  }
  1% {
    opacity: 0;
    height: 100%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes hide {
  0% {
    height: 100%;
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: 100%;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
