@import "../../styles/colors";
@import "../../styles/vars";
@import "../../styles/mixins";

.detailsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -1rem;
}

.prodPic {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $lightGray;
  background-image: url("/images/prod_bg.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 4rem;
  margin: 0 auto;
  width: 100%;
  min-height: 250px;
  max-height: 90vw;
  overflow: hidden;
  img {
    width: 100%;
    max-width: 100%;
  }
  @media (min-width: 1024px) and (max-height: 700px) {
    max-width: 70vh;
    height: 55vh;
    img {
      height: 100%;
      width: auto;
    }
  }
  @media (max-width: 768px) {
    border-radius: 20px;
  }
}
.prodHat {
  height: 8rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    height: 5rem;
    margin-bottom: 2rem;
  }
}
h2.prodName {
  margin-top: 0;
  @media (max-width: 768px) { 
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  padding: 0 2em;
  h3 {
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 1.5em;
  }
  p {
    margin: 1.5em 0;
    font-size: 1em;
    line-height: 1.5em;
    color: $black75;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 0;
  }  
}

.nutritionalValues {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  margin: 7rem 0 3rem 0;
  padding: 0 3rem 3rem 3rem;
  border-radius: 3rem;
  width: 100%;
  background: $lightGray;
  @media (max-width: 768px) {
    gap: 1rem;
  }
}
.nutritionalBlock {
  display: felx;
  align-self: stretch;
  flex-direction: column;
  justify-content: stretch;
  margin-top: -5rem;
  div {
    border-radius: 1.5rem;
    padding: 1em 0;
    background: $black;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    big {
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1.1em;
    }
  }
  div:last-child {
    background: $white;
    color: $black;
    font-weight: bold;
    padding: 1.5rem 0;
    margin-top: 1.5rem;
  }
  @media (max-width: 768px) {
    div {
      font-size: 1.5rem;
    }
  }
}
p.nutritionInfo {
  font-size: 1.5rem;
  line-height: 120%;
  color: $black50;
  grid-column: 1 / span 4;
  margin: 0;
}
.cart {
  transform: scaleX(-1);
}
.outOfStock {
  text-align: right;
  font-weight: normal;
  color: $black50;
  font-size: 1.5rem;
  flex: 0 1 150px;
}
.tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > div {
    margin: 1rem;
  }
}
