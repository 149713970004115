@import "../../styles/colors";

.loginButton,
.loginButton[href] {
  display: flex;
  background: $fbBlue;
  color: $white;
  border-radius: 2rem;
  font-size: 12px;
  padding: 2rem;
  margin: 2rem 0;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 1rem;
    margin-bottom: 2px;
  }
  &:hover {
    background: darken($fbBlue, 20);
    text-decoration: none;
  }
}
