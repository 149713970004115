@import "../../styles/colors";


.logo {
  display: block;
  width: 152px;
  height: 56px; // 56 / 65
  background-image: url('/pobudka_logo.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  &.sticky {
    width: 82px;
    height: 32px;
  }  
}

@media screen and (max-width: 738px) {
  .logo, .logo.sticky {
    background-image: url('/logo_mobile_fixed.png');
    width: 4rem;
    height: 4rem; 
  }

}