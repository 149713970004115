@import '../../styles/colors';
@import '../../styles/mixins';

.title {
    text-transform: uppercase;
    color: $black50;
    letter-spacing: 0.1em;
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
}
.header {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 2em;
    line-height: 1.5em;
    @media (max-width: 768px) {
        font-size: 20px;
        margin-right: 5rem;
    }
}


.basketContent {
    box-sizing: border-box;
    height: 100vh;
    padding-bottom: 150px;
    overflow: auto;
}
.ordersList {
    margin-top: 3rem;
}