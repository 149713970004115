html {
  background: #fff;
  font-size: 8px;
  max-width: 100vw;
}
@media print {
  html {
    font-size: 6px;
  }
}

body {
  margin: 0;
  font-family: 'Rubik', 'Segoe UI', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  line-height: 1.2em;
  background: #fff;
  letter-spacing: 0.04em;
}

body.open {
  overflow: hidden;
}

body * {
  box-sizing: border-box;
  font-family: 'Rubik', 'Segoe UI', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}
p {
  font-size: 1.25rem;
  line-height: 1.25em;
  letter-spacing: 0.04em;
}
h1, h2, h3, h4 {
  line-height: 1.25em;
  margin: 1em 0;
  font-weight: 500;
}

h1 {
  font-size: 5rem;
  font-weight: bold;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.5rem;
  
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 4rem;
  }
}


.uppercase {
  text-transform: uppercase;
}
.relative {
  position: relative;
}
.nobr {
  white-space: nowrap;
}
em,
.highlight {
  font-style: normal;
  font-weight: inherit;
  background: linear-gradient(180deg, transparent 50%, rgba(242, 146, 0, 0.35) 50%, rgba(242, 146, 0, 0.35) 90%, transparent 90%);
}

strong {
  font-weight: 500;
}

.back {
  padding-left: 20px;
  font-weight: 500;
  font-size: 2rem;
  background: url('/icons/back-arrow.svg') left center no-repeat;
}

.black {
  color: #000;
}
.black50 {
  color: rgba(0,0,0,0.5);
}
.red {
  color: #DA1852;
}
pre {
  white-space: pre-wrap;
}

.rules li {
  margin-bottom: 1em;
  line-height: 1.3em;
}
.rules ol {
  margin: 1em 0;
}
.rules {
  font-weight: normal;
  font-size: 10px;

}
.whiteSpace {
	white-space: normal;
}
