@import '../variables.scss';

.sortingArrows {
  white-space: nowrap;
  button {
    margin-top: -6px;
    font-family: monospace;
    font-size: 25px;
    padding: 1px;
    background: transparent;
    border: none;
    outline: none;
    color: $colorNeutral;
    cursor: pointer;
    transition: color $defaultTransitionDuration ease;
    &:hover {
      color: darken($colorNeutral, 30);
    }
    &.active {
      color: $colorPrimary;
      &:hover {
        color: darken($colorPrimary, 20);
      }
    }
  }
}
